body.products{
    .categoria{
        position: relative;
        height: 260px;
        border-radius: 10px;
        overflow: hidden;
        
        img{
            -webkit-filter: grayscale(1); 
            filter: grayscale(1);
            transition: all .3s ease-in;

            &:hover{
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
            }
        }
        &.active{
            img{
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
            }
            h2:before{
                background: #FFF;
            }

        } 

        
        h2{
            color:#FFF;
            font-weight: 100;
            position: absolute;
            bottom: 10px;
            left:20px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-content: flex-start;
            align-items: center;

            &:before{
                content: "";
                width: 10px;
                height: 10px;
                // border: 1px solid #FFF;
                border-radius: 50%;
                outline: 1px solid white;
                outline-offset: 4px;
                display: inline-block;
                margin-right: 10px;
                margin-top: 5px;
            }
        }      
    }


    .list-categorias{
        border:0;
        >li{
            border:0;
            background-color: transparent;  
            &:not(:first-child){
                margin: 0 5px;
            }


            a{
                text-decoration: none;
                color:#909090;
            }

            &.active{
                color:#A5392B;
                border:1px solid #A5392B;
                font-weight: bold;
                font-style: italic;
                border-radius: 8px;

                a{color:#A5392B;}
                
            }

            &:hover{
                color:#A5392B;
                border:1px solid #A5392B;
                font-weight: bold;
                font-style: italic;
                border-radius: 8px;
                a{color:#A5392B;}
                
            }
        }

    
    }

    #filtros{
        background-color: #F0F0F0;
        border-radius: 10px;
        padding:1.5rem;
        h3{
            color:#909090; font-size:20px;
            padding: 15px 0;
            border-bottom: 1px solid #E3E3E3;
        }
        h4{font-weight: bold; font-size:18px;}

        ul{
            list-style: none;
            padding-left:0;

            li{
                a{
                    color:#909090;
                    text-decoration: none;
                    font-size:16px;
                    font-weight: 300;
                    transition: padding-left .3s ease;

                    &:hover{
                        opacity: .5;
                        padding-left: 10px;
                    }
                }

                &:last-child{
                    padding-bottom: 15px;
                    border-bottom: 1px solid #E3E3E3;
                }
            }

            
        }
    }
}
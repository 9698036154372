body.home{
    
    .thumbex{
        margin: 0;
        width: 100%;
        height:100%;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        overflow: hidden;   
        cursor: pointer;
        
        .thumbnail{
            overflow: hidden;
            min-width: 250px;
            height: 100%;
            max-height: 795px;
            
            position: relative;
            opacity: 0.88;
            backface-visibility: hidden;
            transition: all 0.4s ease-out;
            padding-bottom: 166%;

            &:after{           
                content: '';
                background: rgb(0 0 0 / 40%);
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 1;
                transition: all 0.3s ease-out;
            }

            &:hover:after{ background: rgb(0 0 0 / 15%);}
        
        
            img{
                position: absolute;
                z-index: 1;
                left: 50%;
                top: 50%;
                height: 100%;
                width: auto;
                transform: translate(-50%, -50%);
                backface-visibility: hidden;
                object-fit: cover;
            }
        
            span{
                position: absolute;
                z-index: 2;
                bottom: calc(120px - 20px);
                left: 20px;           
                margin: 0 45px;
                text-align: center;
                font-size: 50px;
                color: white;
                font-weight: 100;
                letter-spacing: 0.2px;
                transition: all 0.3s ease-out;
                opacity: 0;
                @media screen and (max-width:600px){
                    opacity: 1;
                }
            }
        
            &:hover{
                backface-visibility: hidden;
                transform: scale(1.15, 1.15);
                opacity: 1;
                
                span{
                    opacity: 1;
                }
            
            }
        }
    }

    section#content-1{
        background-color: #F0F0F0;
        
       
        @media screen and (max-width: 600px){
            padding:3rem 0;
        }

        @include  for-desktop-up {    
            padding:3rem 0 7rem 0;
        }
        @include  for-big-desktop-only {    
            padding:3rem 0 12rem 0;
        }

        p{
            margin-bottom: 0;
            font-size:22px;
            font-weight: 300;

            b, strong{
               color: #A5392B; 
               font-weight: bold;
            }
        }
    }
    section#content-2{
           
        position: relative;
        @include  for-desktop-up {    
            top:-4rem;
        }
        @include  for-big-desktop-only { 
            top:-8rem;
        }
        
        .line{
            display: block; width:70%; height: 2px; background-color: #000;
            position: absolute;
            right: 0;

            @media screen and (max-width: 600px){
               display: none;
            }
        }
        .text-main--home{
            font-weight: 300;
            b, strong{
                color: #A5392B; 
                font-weight: bold;
             }
            @include  for-desktop-up {    
                padding: 4rem 2rem 0rem 2rem;
                font-size:18px;
                
            }            
            @include  for-big-desktop-only {    
                padding:4rem;
                font-size:20px;
            }
        }
    }

    #vitrine{
      

        h2 {
            overflow: hidden;
            text-align: left;
            color:#A5392B;
            font-weight: 100;
            font-size:40px;
        }
        
        h2:after{
            background-color: #000;
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 100%;
        }
        
        h2:after {
            left: 0.5em;
            margin-right: -50%;
        }
        @include   for-big-desktop-only {    
            .ps-md-20{padding-left:20rem !important;}
        }
    }


    @media screen and (max-width: 600px){
        .mobileslider{
            
            .slick-prev{left: 15px;z-index: 1;}
            .slick-next{right: 25px;}
            .slick-prev:before, .slick-next:before {
                font-family: "slick";
                font-size: 35px;
            }
        }
    }

}//endbody
.vitrine-produtos{
    .slick-list{padding:0 5% 0 0 !important;}
    .slick-prev:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='27' viewBox='0 0 25 27'%3E%3Cpath id='Polígono_1' data-name='Polígono 1' d='M10.42,5.7a3.5,3.5,0,0,1,6.159,0l7.632,14.134A3.5,3.5,0,0,1,21.132,25H5.868a3.5,3.5,0,0,1-3.08-5.163Z' transform='translate(0 27) rotate(-90)'/%3E%3C/svg%3E%0A");
    }
 
    .card{
        border:0;
        margin: 10px;
        .features{
            position: absolute;
            top: 15px;
            right: 15px;
            span{
                border:2px solid #FFF;
                font-weight: bold;
                font-size:12px;
                border-radius: 8px;
                color:#FFF;
                padding:3px 10px;
                &:not(:last-child){
                    margin-right: 8px;
                }
            }
        }

        .card-body{
            background:#F0F0F0;
         
        }

        .card-img-top:before{
            content:'';
            position:absolute;
            left:0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: .3;
        }

        .card-title{
            color:#A5392B;
            font-weight: 900;
            font-style:italic;
            font-size: 22px;
            border-bottom: 1px solid #D9D9D9;
            padding-bottom: 10px;
        }
        
    }

   
}
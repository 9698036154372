body.revendedor,
body.especificador,
body.instalador{
    main{
        #navegation{
            background-color: #f0f0f0;
            padding:2rem 0 1rem 0;

            .nav-tabs {
                margin-bottom: -2rem;
                border: 0;

                .nav-link{
                    color:#909090;
                    border:0;
                    &:hover,
                    &:focus{
                        
                        color:#A5392B;
                    }
                    &.active {
                        color:#000;
                        background: transparent;
                        border: 0;
                        border-bottom: 3px solid #A5392B;
                    }
                }
            }


            .welcome{
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: nowrap;

                svg{margin-right: 10px;}

                p:first-child{
                    font-weight: bold;
                    color:#A5392B
                }

                p:last-child{
                    font-weight: 300;
                    color:#000;
                }
            }
        }//navegation

    }
}


// revendedor

body.revendedor{
    // videos treinamento
    main{
        .vid-list-container {
            border:2px solid #CCCCCC;
            padding:1rem;
            border-radius: 10px;
            
            height: 500px;
            overflow: hidden;

        
            &:hover, &:focus {
                overflow-y: auto;
            }


            #vid-list {
                margin: 0;
                padding: 0;
                overflow: hidden;
            
                li {
                    list-style: none;
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                    a {
                        text-decoration: none;
                        height: 85px;
                        display: block;
                
                        &:hover {opacity: .5;}

                        .vid-thumb {
                            float: left;
                            margin-right: 8px;
                        }
                    }
        
                    .desc {
                        color: #000;
                    
                        h4{font-size:16px;font-weight: bold; margin-bottom: 0;}
                        p{font-size:14px; font-weight: 300; margin-bottom: 0;}
                    }
                }
        
            }
        } 

        .video-principal{
            border-radius: 10px;
            border:2px solid #CCCCCC;
            overflow: hidden;
        }
    }

    // videos treinamento

    // material
    &.material{
        main{
            .card{
                border:0;
                margin-bottom:1rem;
                border-radius: 8px;
                overflow: hidden;
            
                .card-body{
                    background:#F0F0F0;
                    position: relative;
                
                }
    
                .card-title{
                    color:#A5392B;
                    font-weight: 900;
                    font-style:italic;
                    font-size: 22px;
                    border-bottom: 1px solid #D9D9D9;
                    padding-bottom: 10px;
                }           
                
                .card-text{
                    font-size:14px;
                }
            }
        }
    }
    // material


    // catalogos
    &.catalogos{
        main{
            .card{
                border:0;
                margin-bottom:1rem;
                border-radius: 8px;
                overflow: hidden;
            
                .card-body{
                    background:#F0F0F0;
                    position: relative;
                
                }
    
                .card-title{
                    color:#A5392B;
                    font-weight: 900;
                    font-style:italic;
                    font-size: 22px;
                    border-bottom: 1px solid #D9D9D9;
                    padding-bottom: 10px;
                }
                .card-text{
                    font-size:14px;
                }
                
            }
            .filtros{
                a{
                color:#AAAAAA; text-decoration: none; font-weight: bold;
                    &.active{
                    color:#A5392B;
                    }
    
                    &:not(:last-child){
                        margin-right: 15px;
                    }
                }
            }


            .btn-download{
                background-color: #64B161;
                display: block;
                color:#FFF;
                font-weight: bold;
                width: 100%;
                text-align: center;
                height: 50px;
                line-height: 35px;
                transition: opacity .2s ease;
                &:hover{
                    opacity: .5;
                }

            }
        }
    }
    // catalogos

    // tabela
    &.tabela{
        main{

            .grid {
                      
                figure {
                    border-radius: 10px;
                    position: relative;
                    float: left;
                    overflow: hidden;
                    min-width: 320px;
                    max-width: 480px;
                    max-height: 360px;
                    width:100%;
                    height: auto;
                    background: #A5392B;
                    text-align: center;
                    cursor: pointer;
              
                    img {
                        position: relative;
                        display: block;
                        min-height: 100%;
                        max-width: 100%;
                        opacity: 0.8;
                    }
                    &::before{
                        content: "";
                        background-color: #000;
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        opacity: .3;
                    }

                    &:hover{
                        &::before{                           
                            opacity: 0;
                        }
                    }
              
                    figcaption {
                        padding: 2em;
                        color: #fff;
                        text-transform: uppercase;
                        font-size: 1.25em;
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;
                        z-index: 2;
              
                        &::before, &::after {
                             pointer-events: none;
                        }
              
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
              
                        > a {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 1000;
                            text-indent: 200%;
                            white-space: nowrap;
                            font-size: 0;
                            opacity: 0;
                        }
                    }
              
                    h2 {
                        word-spacing: -0.15em;
                        font-weight: 300;
                
                        span {
                            font-weight: 800;
                        }
                    
                            margin: 0;
                    }
                
                    p {
                        margin: 0;
                        letter-spacing: 1px;
                        font-size: 68.5%;
                    }
                }
            }
            
            figure.effect-terry {
                background: #34495e;
            
                figcaption {
                padding: 1em;
            
                &::before, &::after {
                    position: absolute;
                    width: 200%;
                    height: 200%;
                    border-style: solid;
                    border-color: #101010;
                    content: '';
                    -webkit-transition: -webkit-transform 0.35s;
                    transition: transform 0.35s;
                }
            
                &::before {
                    right: 0;
                    bottom: 0;
                    border-width: 0 70px 60px 0;
                    -webkit-transform: translate3d(70px, 60px, 0);
                    transform: translate3d(70px, 60px, 0);
                }
            
                &::after {
                    top: 0;
                    left: 0;
                    border-width: 15px 0 0 15px;
                    -webkit-transform: translate3d(-15px, -15px, 0);
                    transform: translate3d(-15px, -15px, 0);
                }
                }
            
                img, p a {
                -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
                transition: opacity 0.35s, transform 0.35s;
                }
            
                img {
                    opacity: 0.85;
                }
            
                h2 {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 0.4em 10px;
                    width: 50%;
                    -webkit-transition: -webkit-transform 0.35s;
                    transition: transform 0.35s;
                    -webkit-transform: translate3d(100%, 0, 0);
                    transform: translate3d(100%, 0, 0);
                }
            
                p {
                float: right;
                clear: both;
                text-align: left;
                text-transform: none;
                font-size: 111%;
            
                a {
                    display: block;
                    margin-bottom: 1em;
                    color: #fff;
                    opacity: 0;
                    -webkit-transform: translate3d(90px, 0, 0);
                    transform: translate3d(90px, 0, 0);
            
                    &:hover, &:focus {
                    color: #A5392B;
                    }
                }
                }
            
                &:hover {
                figcaption {
                    &::before, &::after {
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                    }
                }
            
                img {
                    opacity: 0.6;
                }
            
                h2 {
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                }
            
                p a {
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
            
                    &:first-child {
                    -webkit-transition-delay: 0.025s;
                    transition-delay: 0.025s;
                    }
            
                    &:nth-child(2) {
                    -webkit-transition-delay: 0.05s;
                    transition-delay: 0.05s;
                    }
            
                    &:nth-child(3) {
                    -webkit-transition-delay: 0.075s;
                    transition-delay: 0.075s;
                    }
            
                    &:nth-child(4) {
                    -webkit-transition-delay: 0.1s;
                    transition-delay: 0.1s;
                    }
                }
                }
            }
            
            @media screen and (max-width: 920px) {
                figure.effect-terry h2 {
                padding: 0.75em 10px;
                font-size: 120%;
                }
            }
            
        }
    }
    // tabela


    // pedidos
    &.pedidos{
        main{
            .sidebar {
                position: sticky;
                top: 15px;
                height: 250px;

                button.btn-importante{
                    display: block;
                    background-color: #F0F0F0;
                    color: #A5392B;
                    font-weight: bold;
                    width: 100%;
                    text-align: left;
                    border: 0;
                    height: 50px;
                    border-radius: 10px;

                    &:after {
                        content: "\f0dd";
                        line-height: 20px;
                        font-family: FontAwesome;
                        position: absolute;
                        right: 20px;
                        font-weight: 600;
                    }

                    &[aria-expanded="true"]:after {
                        content: "\f0de";
                        font-family: FontAwesome;
                        font-weight: 600;
                        line-height: 35px;
                    }

                    &[aria-expanded="true"]{
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius:0;
                    }
                      
                    &:focus {
                        outline: 0;
                        box-shadow: none;
                    }
                    
                }
                #avisosImportantes .card.card-body {
                    background: #f0f0f0;
                    border: 0;
                    border-radius: 10px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    color:#909090;
                }


            }


            .form-pedidos{
                .titulo{font-weight: bold;color:#A5392B}
            
                input[type="text"],
                input[type="number"],
                input[type="tel"],
                input[type="email"],
                select{
                    background-color: #F0F0F0;
                    border-radius: 10px;
                    border:1px solid #F0F0F0;
                    height: 50px;
                    font-weight: 500;
                    font-size:14px;
                    color:#000;
    
                    &::placeholder,
                    &:-moz-placeholder{
                        
                        color:#909090;
                    }
                }

                .radios{
                    background: #f0f0f0;
                    border-radius: 10px;
                    padding: 12px;
                    height: 50px;
                    font-weight: 500;
                    font-size:14px;

                    >span{
                        padding-bottom: 10px;
                        margin-top: -5px;
                        padding-right: 5px;
                        margin-right: 10px;
                    }
                }
    

                .bg-custom{
                    background: #f0f0f0;
                    border-radius: 10px;
                    padding: 12px;
                    font-weight: 500;
                    font-size:16px;
                    margin: 0;
                }
    
                textarea{
                    background-color: #F0F0F0;
                    border-radius: 10px;
                    border:1px solid #F0F0F0;
                    font-weight: 500;
                    font-size:14px;
                    color:#000;
    
                    &::placeholder,
                    &:-moz-placeholder{
                        color:#909090;
                    }
                }
    
                .input-group-text{
                    border-radius: 10px;
                    background-color: #F0F0F0;
                    border:1px solid #F0F0F0;
                    font-size:14px; font-weight: 500;
                    color:#000;
                    border-right:2px solid #C7C7C7;
                }
    
                button[type="submit"]{
                    color:#FFF;
                    font-weight: bold;
                    height: 50px;
                    border-radius: 10px;
                    background-color: #64B161;
                }
    
    
                
            }
        }
    }
    //pedidos

    //politicas
    &.politicas{
        .btn-download{
            color:#FFF;
            font-weight: bold;
            background-color: #A5392B;
            border-color: #A5392B;
        }
    }

}

body.especificador{

    main{
        .card{
            border:0;
            margin-bottom:1rem;
            border-radius: 8px;
            overflow: hidden;
        
            .card-body{
                background:#F0F0F0;
                position: relative;
            
            }

            .card-title{
                color:#A5392B;
                font-weight: 900;
                font-style:italic;
                font-size: 22px;
                border-bottom: 1px solid #D9D9D9;
                padding-bottom: 10px;
            }
            .card-text{
                font-size:14px;
            }
            
        }
        .filtros{
            a{
            color:#AAAAAA; text-decoration: none; font-weight: bold;
                &.active{
                color:#A5392B;
                }

                &:not(:last-child){
                    margin-right: 15px;
                }
            }
        }


        .btn-download{
            background-color: #64B161;
            display: block;
            color:#FFF;
            font-weight: bold;
            width: 100%;
            text-align: center;
            height: 50px;
            line-height: 35px;
            transition: opacity .2s ease;
            &:hover{
                opacity: .5;
            }

        }

        .title{
            font-weight: bold;
        }

        .subtitle{
            font-size:14px;
        }
       
       
    }
  
}


body.instalador{
    main{
        .vid-list-container {
            border:2px solid #CCCCCC;
            padding:1rem;
            border-radius: 10px;
            
            height: 500px;
            overflow: hidden;

        
            &:hover, &:focus {
                overflow-y: auto;
            }


            #vid-list {
                margin: 0;
                padding: 0;
                overflow: hidden;
            
                li {
                    list-style: none;
                    &:not(:last-child){
                        margin-bottom: 10px;
                    }
                    a {
                        text-decoration: none;
                        height: 85px;
                        display: block;
                
                        &:hover {opacity: .5;}

                        .vid-thumb {
                            float: left;
                            margin-right: 8px;
                        }
                    }
        
                    .desc {
                        color: #000;
                    
                        h4{font-size:16px;font-weight: bold; margin-bottom: 0;}
                        p{font-size:14px; font-weight: 300; margin-bottom: 0;}
                    }
                }
        
            }
        } 

        .video-principal{
            border-radius: 10px;
            border:2px solid #CCCCCC;
            overflow: hidden;
        }
    }
}


body.revendedor,
body.especificador,
body.instalador{
    &.modal-open section#content,
    &.modal-open section#navegation{
        -webkit-filter: blur(10px);
        -moz-filter: blur(10px);
        -o-filter: blur(10px);
        -ms-filter: blur(10px);
        filter: blur(10px);
    }
    
    #welcomeModal,
    #registerModal{
        .modal-content{
            border:0;
            border-radius: 10px;
            .modal-body {
                padding: 2rem 4rem;
            }
        }
        
        .modal-title{
            color:$persiflex;
            font-size:35px;     
            font-weight: bold;   
        }
        
        .back-link{
            margin-top: 5px;
            a{color:#2c2c2c;text-decoration: none; font-size:14px; font-weight: 300; }
        }

        form{
            input{
                background-color: #F0F0F0;
                border:0;
                height: 50px;
                font-size: 16px;
            }
            textarea{
                background-color: #F0F0F0;
                border:0;
                resize:none;                
                font-size: 16px;
            }
            label{
                font-size:14px;
                color:#000;
            }

            button{
                background-color: #64B161;
                color:#FFF;
                border:0;
                font-weight: bold;
                height: 50px;

                &:hover{
                    background-color: #64B161;
                    opacity: .7;
                    color:#FFF;
                }
            }
        }

        hr{
            border-color: #E3E3E3;
        }

        .text-primeiroacesso{
            color:#001E2F;
            font-weight: 900;
        }
        .link-primeiroacesso{
            color:#000;
            font-weight: bold;
        }
    }
}
body.blog{  
     main{
        .card{
            border:0;
            margin-bottom:1rem;
            border-radius: 8px;
            overflow: hidden;
        
            .card-body{
                background:#F0F0F0;
                position: relative;
            
            }

            .card-title{
                color:#A5392B;
                font-weight: 900;
                font-style:italic;
                font-size: 22px;
                border-bottom: 1px solid #D9D9D9;
                padding-bottom: 10px;
            }

            .data{
                color:#AAAAAA;
                font-size: 14px;
            }
            
        }

        .filtros{
            a{
            color:#AAAAAA; text-decoration: none; font-weight: bold;
                &.active{
                color:#A5392B;
                }

                &:not(:last-child){
                    margin-right: 15px;
                }
            }
          }
     }
}
body.home{
    #topbar{
        background-color: #FFF;
        padding:10px 0;
        
        a{
            color:#000000;
            text-decoration: none;
            font-size:14px; 
            font-weight: 500;
        }
    
        
    }
}

body:not(.home){
    #topbar{
        background-color: #000;
        padding:10px 0;
        
        a{
            color:#FFF;
            text-decoration: none;
            font-size:14px; 
            font-weight: 500;
        }
    
        
    }
}
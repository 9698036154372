body.contact{
    main{
        h2,h3,h4{color:$persiflex}
        h2{
            font-size:30px;     
            font-weight: 600;   
        }

        h3{
            font-weight: bold;
            font-size:20px;
        }
    
        p{
           color:#909090 
        }


        form{
            input{
                background-color: #F0F0F0;
                border:0;
                height: 50px;
                font-size: 16px;
            }
            textarea{
                background-color: #F0F0F0;
                border:0;
                resize:none;                
                font-size: 16px;
            }
            label{
                font-size:14px;
                color:#000;
            }

            button{
                background-color: #64B161;
                color:#FFF;
                border:0;
                font-weight: bold;
                height: 50px;

                &:hover{
                    background-color: #64B161;
                    opacity: .7;
                    color:#FFF;
                }
            }
        }
    }
}
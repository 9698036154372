#newsletter{
    background-image: url('/images/bg-newsletter.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding:2rem 0 ;

    h3{
        color:#FFF;
        font-size:22px;
        font-weight: bold;

    }

    form{
        input[type='text'],
        input[type='email']{
            height: 50px;
            background-color: rgba(255, 255, 255, 0.8);
            
        }
        .form-check{
            color:#FFF;
            line-height: 6px;
            font-weight: bold;
            min-height: 0;
            font-size:14px;
            @media screen and(max-width: 600px){
                line-height: 14px;
            }
        }
        .form-check-input {
            margin-top: -3px;
            @media screen and(max-width: 600px){
                margin-top:7px;
            }
        }

        .btn-success{
            background-color: #30AA35;
            border:0;
            font-weight: bold;
            color:#FFF;
        }
    }
}
footer{
    background-color: #F0F0F0;
    padding:2rem 0;
    
    
    .contact--televendas,
    .contact--whatsapp{
        font-weight: bold;
        color:#A5392B;

        .contact {
            display: flex;
            font-weight: 900;
            align-items: center;

            span {
                margin-right: 5px;
            }
        }
    }

    .contact-email{
        font-weight: 300;

        b{
            font-weight: bold;
            color:#A5392B;
        }
    }

    .contact-address{
        font-weight: 300;
    }


    .list-group{
        .list-group-item{
            position: relative;
            display: block;
            padding: 0.5rem 1rem;
            color: #000;
            text-decoration: none;
            background-color: transparent;
            border: 0;

            a{
                color:#000;
                font-weight: bold;
                text-decoration: none;
                &:hover{
                    opacity: .5;
                }
            }
        }
    }

    .links--areas{
        color:#000;
        font-weight: 500;
        text-decoration: none;
        &:hover{
            opacity: .5;
        }
    }

    ul.socials {
        list-style: none;
        display: flex;
        margin-bottom: 0;

        li:not(:last-child){            
            margin-right: 10px;
        }
        .social-icon {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            font-size: 30px;
            cursor: pointer;
        }   
       
        
        .social-icon i {
            transition: 0.4s all
        }
        
        .social-icon:hover i {
            transform: scale(1.1)
        }
    }

}

section#subfooter{
    .links-importants{
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        li{
            display: inline;
            a{color:#000; text-decoration: none;}
        }
    }
    background-color: #FFF;
    
    padding:8px 0;
}
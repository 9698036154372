body.home{
    nav{
        &.navbar{
            position: absolute;
            left:0;
            right: 0;
            z-index: 10;
    
            height: 100px;
            transition: all .5s ease-in-out;
            .navbar-brand > svg{
                transition: width .5s ease-in-out;
                
            }
            &.scroll-effect {
                background-color: #A5392B;
                position: fixed;
                height: 60px;
                @media screen and (max-width: 600px){
                    height: 80px;
                }
                top: 0;
                left: 0;
                right: 0;
                @include box-shadow(0px 3px 10px 0px rgb(85 85 85 / 90%));
    
                .navbar-brand > svg{width:100px}
            }
    
            &.navbar-light .navbar-nav .nav-link {
                color: rgba(255, 255, 255, 1);
                font-weight: 500;
                font-size:16px;
                transition: opacity .3s;

                @media screen and (max-width: 600px){
                    color: #b05246;
                }
    
                &:hover{
                    opacity: .6;
                }
            }
        }
       
    
    
        ul.socials {
            list-style: none;
            display: flex;
            margin-bottom: 0;
    
            li:not(:last-child){            
                margin-right: 10px;
            }
            .social-icon {
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 25px;
                cursor: pointer;

                @media screen and (max-width: 600px){
                    color: #000;
                }
            }   
           
            
            .social-icon i {
                transition: 0.4s all
            }
            
            .social-icon:hover i {
                transform: scale(1.1)
            }
            a{
                text-decoration: none;
            }
        }
        
       
    }
}

body:not(.home){
    nav{
        &.navbar{
            position: relative;
            left:0;
            right: 0;
            z-index: 10;    
            height: 100px;
            transition: all .5s ease-in-out;
            border-bottom: 1px solid #E3E3E3;
            .navbar-brand > svg{
                transition: width .5s ease-in-out;

                path{
                    fill: #000
                }
            }

            &.navbar-light .navbar-nav .nav-link {
                color: #000;
                font-weight: 500;
                font-size:16px;
                transition: opacity .3s;
         
                &:hover{
                    color:#A5392B;
                    opacity: .6;
                }

                &.active{
                    color:#A5392B;
                }
            }


            &.scroll-effect {
                background-color: #A5392B;
                position: fixed;
                height: 60px;
                @media screen and (max-width: 600px){
                    height: 80px;
                }
                top: 0;
                left: 0;
                right: 0;
                border: 0px;
                @include box-shadow(0px 3px 10px 0px rgb(85 85 85 / 90%));
    
                .navbar-brand > svg{
                    width:100px;  
                    
                    path{
                        fill: #FFF
                    }                 
                }

                .nav-link {
                    color: #FFF !important;
                    font-weight: 500;
                    font-size:16px;
                    transition: opacity .3s;
        
                    &:hover{
                        color:#000;
                        opacity: .6;
                    }
                }

                .social-icon {
                    color: #FFF;
                }   

                
            }
    
           
        }
       
    
    
        ul.socials {
            list-style: none;
            display: flex;
            margin-bottom: 0;
    
            li:not(:last-child){            
                margin-right: 10px;
            }

            .social-icon {
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000;
                font-size: 25px;
                cursor: pointer;
            }   
           
            
            .social-icon i {
                transition: 0.4s all
            }
            
            .social-icon:hover i {
                transform: scale(1.1)
            }

            a{
                text-decoration: none;
            }
        }
        
       
    }
}


@media screen and (max-width: 600px){
    .navbar-collapse{
        background-color: #f8fafc;
        padding: 1rem 2rem;
    }

    .scroll-effect .navbar-collapse{
        background-color: #a5392b;
        padding: 1rem 2rem;
    }

    body.home nav.navbar.navbar-light.scroll-effect  .navbar-collapse .navbar-nav .nav-link {
        color: #FFF;
    }
}
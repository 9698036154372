body.post-single{
    .back-link{
        color:#000;
        font-weight: bold;
        text-decoration: none;
    }


    .post-title{
        font-weight: 900;
        font-size:40px;
    }

    .post-date{color:#AAAAAA;font-weight: bold;}

   




}
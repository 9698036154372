body.agendar-visita{
    main{
        section#topvisita{
            background-image: url('/images/bg-agendar.jpg');
            background-repeat: no-repeat;
            padding:3rem 0 15rem 0;
            background-size: cover;


            color:#FFF;


        }


        section#content{
            position: relative;
            margin-top: -14rem;
            margin-bottom: 2rem;
        }

        .form-visita{
            h2{font-weight: bold;}
        
            input[type="text"],
            input[type="number"],
            input[type="tel"],
            input[type="email"]{
                background-color: #F0F0F0;
                border-radius: 10px;
                border:1px solid #F0F0F0;
                height: 50px;
                font-weight: 500;
                font-size:14px;
                color:#000;

                &::placeholder,
                &:-moz-placeholder{
                    
                    color:#909090;
                }
            }


            textarea{
                background-color: #F0F0F0;
                border-radius: 10px;
                border:1px solid #F0F0F0;
                font-weight: 500;
                font-size:14px;
                color:#000;

                &::placeholder,
                &:-moz-placeholder{
                    color:#909090;
                }
            }

            .input-group-text{
                border-radius: 10px;
                background-color: #F0F0F0;
                border:1px solid #F0F0F0;
                font-size:14px; font-weight: 500;
                color:#000;
                border-right:2px solid #C7C7C7;
            }

            button[type="submit"]{
                color:#FFF;
                font-weight: bold;
                height: 50px;
                border-radius: 10px;
                background-color: #64B161;
            }


            
        }


           
    }
}
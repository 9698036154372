body.product-single{
   main{
        .back-link{
            color:#000;
            font-weight: bold;
            text-decoration: none;
        }
        .banner{
            position:relative;
            .features{
                position: absolute;
                top: 15px;
                left: 15px;z-index: 1;
                span{
                    border:2px solid #FFF;
                    font-weight: bold;
                    font-size:12px;
                    border-radius: 8px;
                    color:#FFF;
                    padding:3px 10px;
                    &:not(:last-child){
                        margin-right: 8px;
                    }
                }
            }
        }


        .title-product{
            font-weight: 900;
            font-size:40px;
        }

        .slider{
            .slick-prev:before, .slick-next:before{
                opacity: 1;
            }
            .slick-prev{
                left: 0px;
                z-index: 2;
            }
            .slick-next{
                right: 10px;
            }
            .slick-prev:before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='27' viewBox='0 0 25 27'%3E%3Cpath id='Polígono_1' data-name='Polígono 1' d='M10.42,5.7a3.5,3.5,0,0,1,6.159,0l7.632,14.134A3.5,3.5,0,0,1,21.132,25H5.868a3.5,3.5,0,0,1-3.08-5.163Z' transform='translate(0 27) rotate(-90)'/%3E%3C/svg%3E%0A");
                background: #FFF;
                padding: 10px 10px 2px 2px;
            }

            .slick-next:before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='27' viewBox='0 0 25 27'%3E%3Cpath id='arrow-right' d='M10.42,5.7a3.5,3.5,0,0,1,6.159,0l7.632,14.134A3.5,3.5,0,0,1,21.132,25H5.868a3.5,3.5,0,0,1-3.08-5.163Z' transform='translate(25) rotate(90)'/%3E%3C/svg%3E%0A");
                background: #FFF;
                padding: 10px 12px 2px 10px;
            }
            .slick-dots {
                position: absolute;
                bottom: 15px;
            }
            .slick-dots li button:before{
                color: #FFF;
                opacity:1;
                border-radius: 50%;
                font-size: 10px;
                line-height: 18px;
            }

            .slick-dots li.slick-active button:before {
                color: #FFF;
                opacity:1;
                background: #FFF;
                width: 15px;
                height: 15px;
            }
        }

        #arquivo-modelagem{
            background-color: #000;
            padding:1.2rem 2.5rem;
            border-radius: 10px;
            p{color:#FFF;margin-bottom: 0;}

            form{
                .form-check-label{
                    color:#FFF;
                    font-size:14px;
                }
                input[type="email"]{
                    border-radius: 0;
                    background-color: #000;
                    color:#FFF;
                    border:0;
                    border-bottom: 1px solid #FFF;
                }
                .btn-success{
                    background-color: #000;
                    border:2px solid #FFF;
                    color:#FFF;
                    font-weight: bold;
                    border-radius: 0;
                    padding:.5rem 2rem;
                    width: 100%;
                    display: inline-block;
                }
            }
        }


        #vitrine-semelhantes{
        

            h2 {
                overflow: hidden;
                text-align: left;
                color:#A5392B;
                font-weight: 100;
                font-size:40px;
            }
            
            h2:after{
                background-color: #000;
                content: "";
                display: inline-block;
                height: 1px;
                position: relative;
                vertical-align: middle;
                width: 100%;
            }
            
            h2:after {
                left: 0.5em;
                margin-right: -50%;
            }
            @include   for-big-desktop-only {    
                .ps-md-20{padding-left:20rem !important;}
            }
        }

        .vitrine-produtos{
            .slick-prev{
                left: 10px;
                z-index: 2;
            }
            .slick-next{
                transform: rotate(180deg);
                right: 0px;
            }
            .slick-next:before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='27' viewBox='0 0 25 27'%3E%3Cpath id='Polígono_1' data-name='Polígono 1' d='M10.42,5.7a3.5,3.5,0,0,1,6.159,0l7.632,14.134A3.5,3.5,0,0,1,21.132,25H5.868a3.5,3.5,0,0,1-3.08-5.163Z' transform='translate(0 27) rotate(-90)'/%3E%3C/svg%3E%0A");
                
            }
        }
   }
}
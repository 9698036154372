.prateleira{
  
    .card{
        border:0;
        margin-bottom:1rem;
        border-radius: 8px;
        overflow: hidden;
        .features{
            position: absolute;
            top: 15px;
            right: 15px;z-index: 1;
            span{
                border:2px solid #FFF;
                font-weight: bold;
                font-size:12px;
                border-radius: 8px;
                color:#FFF;
                padding:3px 10px;
                &:not(:last-child){
                    margin-right: 8px;
                }
            }
        }

        .card-body{
            background:#F0F0F0;
            position: relative;
         
        }

        .thumbnail:before{
            content:'';
            position:absolute;
            left:0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: .3;
        }

        .card-title{
            color:#A5392B;
            font-weight: 900;
            font-style:italic;
            font-size: 22px;
            border-bottom: 1px solid #D9D9D9;
            padding-bottom: 10px;
        }
        
    }

   
}